import { Button, Flex, Image, Loader, Menu, Text } from "@mantine/core"
import { functions } from "../../../App";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import cancelIcon from "./../../../assets/cross.svg";
import allSettingsIcon from "./../../../assets/list.png";
import receiptsIcon from "./../../../assets/text.png";

const ManageSubscription = () => {
    const { t } = useTranslation(['common']);

    const [loadingCancelSubscription, setLoadingCancelSubscription] = useState(false);

    const cancelSubscription = () => {
        if (loadingCancelSubscription) return;

        setLoadingCancelSubscription(true)

        const cancelSubscription = functions.httpsCallable('cancelUserSubscription');

        cancelSubscription({})
            .then(result => {
                setLoadingCancelSubscription(false);
            })
            .catch(error => {
                setLoadingCancelSubscription(false);

                console.error('Error canceling subscription:', error);
            });
    }

    return (
        <Menu shadow="md" width={240}>
            <Menu.Target>
                <Button color={"dark"} miw={"100%"} mt={"1rem"}>{t("upgrade.manage-subscription.title")}</Button>
            </Menu.Target>

            <Menu.Dropdown>
                <Menu.Item onClick={() => cancelSubscription()}>
                    <Flex align={"center"} columnGap={"0.5rem"}>
                        {loadingCancelSubscription ? <Loader /> : <Image src={cancelIcon} alt="" height={10} width={10} />}

                        <Text fw={600}>{t("upgrade.manage-subscription.cancel")}</Text>
                    </Flex>
                </Menu.Item>
                <Menu.Item fw={600} onClick={() => window.location.href = "https://billing.stripe.com/p/login/9AQdTc2OY5eIdz29AA"}>
                    <Flex align={"center"} columnGap={"0.5rem"}>
                        <Image src={receiptsIcon} alt="" height={14} width={14} />

                        <Text fw={600}>{t("upgrade.manage-subscription.receipts")}</Text>
                    </Flex>
                </Menu.Item>
                <Menu.Item fw={600} onClick={() => window.location.href = "https://billing.stripe.com/p/login/9AQdTc2OY5eIdz29AA"}>
                    <Flex align={"center"} columnGap={"0.5rem"}>
                        <Image src={allSettingsIcon} alt="" height={14} width={14} />

                        <Text fw={600}>{t("upgrade.manage-subscription.all-settings")}</Text>
                    </Flex>
                </Menu.Item>
            </Menu.Dropdown>
        </Menu>
    )
}

export default ManageSubscription;